import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import ApiService from "./services/ApiService";


interface AuthContextType {
    isAuthenticated: boolean;
    profile: any; // Replace 'any' with your profile type
    profileUpdating: boolean;
    requestOTP: (email: string) => Promise<JSON>;
    login: (email: string, otp: string) => Promise<void>;
    signup: (email: string, otp: string, firstName: string, lastName: string, companyName: string) => Promise<void>;
    logout: () => void;
    refreshProfile: (maxAttempts?: any) => void;
}

export const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const [profile, setProfile] = useState<any>(null); // Replace 'any' with your profile type
    const [profileUpdating, setProfileUpdating] = useState<boolean>(false)

    useEffect(() => {
        const checkAuth = async () => {
            const authStatus = ApiService.isAuthenticated();
            setIsAuthenticated(authStatus);
            if (authStatus) {
                const profileData = await ApiService.fetchProfile();
                console.log("Profile data: " + JSON.stringify(profileData))
                setProfile(profileData);
            }
        };
        checkAuth().then(r => {console.log("Checked Auth")});
    }, []);

    const login = async (email: string, otp: string) => {
        await ApiService.verifyOTP(email, otp);
        setIsAuthenticated(true);
        const profileData = await ApiService.fetchProfile();
        setProfile(profileData);
    };

    const signup = async (email: string, otp: string, firstName: string, lastName: string, organization: string) => {
        await ApiService.verifyOTP(email, otp);
        setIsAuthenticated(true);
        const profileData = await ApiService.fetchProfile();
        profileData['firstName'] = firstName
        profileData['lastName'] = lastName
        profileData['organization'] = organization
        await ApiService.updateProfile(profileData)
        setProfile(profileData);
    };

    const logout = () => {
        ApiService.logout();
        setIsAuthenticated(false);
        setProfile(null);
    };

    const refreshProfile = async (maxAttempts: number = 15) => {
        if (isAuthenticated) {
            setProfileUpdating(true)

            let isUpdating = true
            let profileData = profile
            let attempts = 0

            while (isUpdating && attempts < maxAttempts) {
                try {
                    profileData = await ApiService.fetchProfile()
                    isUpdating = profile?.plan === profileData?.plan && profile?.accountBalance === profileData?.accountBalance;

                    if (isUpdating) {
                        console.log("Data is still updating, fetching again...");
                        // Wait for a specified time before the next fetch
                        await new Promise((resolve) => setTimeout(resolve, Math.ceil((Math.pow(1.25, attempts) - 1) * 100))); // (1.5^x - 1) * 0.12s
                    }
                } catch (error) {
                    console.error("Error fetching data:", error);
                    // Optional: Decide whether to break or continue on error
                }

                attempts++
            }

            setProfile(profileData)
            setProfileUpdating(false)

            return isUpdating
        }

        return true
    }

    const requestOTP = (email: string) => ApiService.requestOTP(email)

    return (
        <AuthContext.Provider value={{ isAuthenticated, profile, profileUpdating, requestOTP, login, signup, logout, refreshProfile}}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error("useAuth must be used within an AuthProvider");
    }
    return context;
};
