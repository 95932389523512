// ParameterForm.js
import React, { useState, useEffect } from 'react';
import { TextInput, Select } from "@mantine/core";
import TextSingleFileUploadOrAssetComponent from "./TextSingleFileUploadOrAssetComponent";
import ImageSingleFileUploadOrAssetComponent from "./ImageSingleFileUploadOrAssetComponent";
import PDFSingleFileUploadOrAssetComponent from "./PDFSingleFileUploadOrAssetComponent";

const ParameterForm = ({ parameters, formValues, handleFormChange, optionsFilter }) => {
    // State to store the mapping of parameter IDs to their key-value pairs
    const [parameterValueMap, setParameterValueMap] = useState({});

    useEffect(() => {
        const newParameterValueMap = {};
        parameters.forEach(param => {
            if (param.ui && param.ui.type.toLowerCase() === 'list') {
                const paramId = param.id;
                if (Array.isArray(param.ui.lookup)) {
                    param.ui.lookup.forEach(item => {
                        if (typeof item === 'object' && !Array.isArray(item)) {
                            for (const key in item) {
                                if (item.hasOwnProperty(key)) {
                                    const values = item[key];
                                    if (!newParameterValueMap[paramId]) newParameterValueMap[paramId] = {};
                                    newParameterValueMap[paramId][key] = values;
                                }
                            }
                        }
                    });
                } else if (typeof param.ui.lookup === 'object' && !Array.isArray(param.ui.lookup)) {
                    newParameterValueMap[paramId] = param.ui.lookup;
                }
            }
        });
        setParameterValueMap(newParameterValueMap);
    }, [parameters]);

    return (
        <>
            {parameters.map(param => {
                if (param.ui) {
                    switch (param.ui.type.toLowerCase()) {
                        case 'text':
                            return (
                                <TextSingleFileUploadOrAssetComponent
                                    key={param.id}
                                    label={param.name}
                                    type="text"
                                    required
                                    placeholder={param.ui.placeholder}
                                    variant="unstyled"
                                    style={{ marginTop: '15px' }}
                                    resize="vertical"
                                    id={param.id}
                                    value={formValues[param.id]}
                                    onValueChange={(newValue) => handleFormChange(newValue, param.id)}
                                />
                            );
                        case 'image':
                            return (
                                <ImageSingleFileUploadOrAssetComponent
                                    key={param.id}
                                    label={param.name}
                                    type="text"
                                    required
                                    placeholder={param.ui.placeholder}
                                    variant="unstyled"
                                    style={{ marginTop: '15px' }}
                                    resize="vertical"
                                    id={param.id}
                                    value={formValues[param.id]}
                                    onValueChange={(newValue) => handleFormChange(newValue, param.id)}
                                />
                            );
                        case 'pdf':
                            return (
                                <PDFSingleFileUploadOrAssetComponent
                                    key={param.id}
                                    label={param.name}
                                    type="text"
                                    required
                                    placeholder={param.ui.placeholder}
                                    variant="unstyled"
                                    style={{ marginTop: '15px' }}
                                    resize="vertical"
                                    id={param.id}
                                    value={formValues[param.id]}
                                    onValueChange={(newValue) => handleFormChange(newValue, param.id)}
                                />
                            );
                        case 'line':
                            return (
                                <TextInput
                                    key={param.id}
                                    label={param.name}
                                    type="text"
                                    required
                                    placeholder={param.ui.placeholder}
                                    style={{ marginTop: '15px' }}
                                    id={param.id}
                                    value={formValues[param.id]}
                                    onChange={(event) => handleFormChange(event.target.value, param.id)}
                                />
                            );
                        case 'list':
                            let options = [];
                            let disabled = false;

                            if (param.ui.dependsOn) {
                                const parentParamId = param.ui.dependsOn;
                                const parentValue = formValues[parentParamId];

                                if (parentValue && param.ui.lookup[parentValue]) {
                                    const lookupOptions = param.ui.lookup[parentValue];

                                    if (typeof lookupOptions === 'object' && !Array.isArray(lookupOptions)) {
                                        options = Object.entries(lookupOptions).map(([key, label]) => ({
                                            value: String(key),
                                            label: String(label) || String(key),
                                        }));
                                    } else if (Array.isArray(lookupOptions)) {
                                        options = lookupOptions.map(value => ({
                                            value: String(value),
                                            label: String(value),
                                        }));
                                    }
                                } else {
                                    disabled = true;
                                }
                            } else {
                                if (Array.isArray(param.ui.lookup)) {
                                    param.ui.lookup.forEach(item => {
                                        if (typeof item === 'object' && !Array.isArray(item)) {
                                            for (const key in item) {
                                                if (item.hasOwnProperty(key)) {
                                                    options.push({
                                                        value: String(key),
                                                        label: String(key),
                                                    });
                                                    // Store the mapping of key to value
                                                    if (!parameterValueMap[param.id]) parameterValueMap[param.id] = {};
                                                    parameterValueMap[param.id][key] = item[key];
                                                }
                                            }
                                        } else {
                                            options.push({
                                                value: String(item),
                                                label: String(item),
                                            });
                                        }
                                    });
                                } else if (typeof param.ui.lookup === 'object' && !Array.isArray(param.ui.lookup)) {
                                    options = Object.entries(param.ui.lookup).map(([key, label]) => ({
                                        value: String(key),
                                        label: String(label) || String(key),
                                    }));
                                    parameterValueMap[param.id] = param.ui.lookup;
                                } else if (typeof param.ui.lookup === 'string') {
                                    options = param.ui.lookup.split('|').map(value => ({
                                        value: String(value),
                                        label: String(value),
                                    }));
                                }
                            }

                            const selectKey = param.id + (param.ui.dependsOn ? `-${formValues[param.ui.dependsOn] || ''}` : '');

                            return (
                                <Select
                                    key={selectKey}
                                    label={param.name}
                                    data={options}
                                    required
                                    placeholder={param.ui.placeholder}
                                    style={{ marginTop: '15px' }}
                                    id={param.id}
                                    checkIconPosition="right"
                                    filter={optionsFilter}
                                    searchable
                                    value={formValues[param.id] || ''}
                                    onChange={(value) => handleFormChange(value, param.id)}
                                    disabled={disabled}
                                />
                            );
                        default:
                            return <div key={param.id}>Unknown parameter type</div>;
                    }
                }
                return null;
            })}
        </>
    );
};

export default ParameterForm;
